.price {
  max-width: 1100px;
  width: 100%;
  margin: auto;
  margin-bottom: 180px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    padding: 15px;
    margin-bottom: 60px;
  }

  &__wrapper {
    background-image: url("../../../../public/images/priceline.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 0px;
    width: 100%;
    margin-top: -50px;
  }

  .split {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    img {
      height: auto;
      width: 50%;
    }
  }

  .header {
    font-family: "FuturaBookC", sans-serif;
    font-weight: 400;
    font-size: 54px;
    margin-bottom: 47px;
    margin-top: 200px;
    @media screen and (max-width: 1024px) {
      margin-top: 150px;
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 36px;
    }
  }

  .subheader {
    margin-bottom: 60px;
    @media screen and (max-width: 1024px) {
      font-size: 22px;
      margin-bottom: 59px;
      font-weight: 500;
    }
  }

  .description {
    font-size: 28px;
    margin: 47px auto 64px;
    max-width: 660px;
    @media screen and (max-width: 1024px) {
      font-size: 22px;
      margin-top: 0;
      margin-bottom: 59px;
      font-weight: 600;
    }

    img {
      margin-bottom: -8px;
      margin-right: 2px;
    }
  }

  &__list {
    position: relative;
    display: flex;
    justify-content: center;
    column-gap: 100px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      row-gap: 100px;
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    padding: 36px 44px 36px 44px;
    border-radius: 20px;
    background: #68a9b8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 470px;
    margin-bottom: 55px;

    @media screen and (max-width: 1024px) {
      margin: auto;
      width: 90%;
      padding: 26px 26px 32px 26px;
      // height: 482px;
      height: auto;
    }

    &:last-child {
      background: linear-gradient(180deg, #004f69, #67a8b7) !important;
    }

    * {
      color: white !important;
    }

    &_header {
      font-family: "FuturaBookC", sans-serif;
      font-size: 42px;
      font-weight: 300;
      margin-bottom: 12px;
      @media screen and (max-width: 1024px) {
        font-size: 24px;
        font-weight: 600;
      }
    }
    &_subheader {
      font-size: 28px;
      margin-bottom: 30px;
      text-align: left;
      @media screen and (max-width: 1024px) {
        font-size: 21px;
        font-weight: 600;
        text-align: center;
      }
    }
    &_list {
      text-align: left;
      margin-left: 8px;
      flex: 1 1 auto;
      margin-bottom: 48px;

      @media screen and (max-width: 1024px) {
        margin-bottom: 34px;
      }

      li {
        font-size: 24px;
        @media screen and (max-width: 1024px) {
          font-size: 21px;
          font-weight: 500;
        }
      }

      &_mt {
        margin-top: 20px;
      }

      li::marker {
        content: "- ";
      }
    }

    &_border {
      position: relative;
      border: 5px solid white;
      border-radius: 15px;
      padding: 15px 15px 5px 15px;
      text-align: left;

      span {
        font-family: "Roboto", sans-serif;
        font-size: 38px;
      }
    }
    &_discount {
      font-family: "FuturaBookC", sans-serif;
      color: #60a0b1 !important;
      background: white;
      font-size: 21px;
      padding: 8px 12px;
      border-radius: 20px;
      width: max-content;
      @media screen and (max-width: 1024px) {
        position: absolute;
        top: -20px;
        left: 16px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    &_price {
      font-family: "FuturaBookC", sans-serif;
      font-size: 52px;
      font-weight: 200;
      margin: 12px 0 8px;
      @media screen and (max-width: 1024px) {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 0;
      }

      b {
        font-family: "FuturaBookC", sans-serif;
        font-weight: 400;
        font-size: 52px;
        @media screen and (max-width: 1024px) {
          font-size: 36px;
        }
      }
    }
    &_oldprice {
      color: #4b7a85 !important;
      font-size: 34px;
      font-family: "FuturaBookC", sans-serif;
      text-decoration: line-through;
      @media screen and (max-width: 1024px) {
        font-size: 28px;
      }
    }
    &_coffe {
      position: absolute;
      right: 10px;
      bottom: 10px;
      @media screen and (max-width: 1024px) {
        width: 40px;
        height: 40px;
      }
    }

    &_button {
      font-family: "FuturaBookC", sans-serif;
      position: absolute;
      bottom: -85px;
      font-weight: 600;
      color: #91d8de !important;
      font-size: 28px;
      background: white;
      padding: 15px;
      border-radius: 10px;
      width: 382px;
      cursor: pointer;

      &_select {
        bottom: -45px !important;
        text-align: center;
        box-shadow: 0px 6px 10px 0px lightgray;
        transition: all 0.5s ease-in-out;

        &:hover {
          box-shadow: 0px 6px 15px 5px lightgray;
        }

        @media screen and (max-width: 1024px) {
          width: calc(100% - 52px);
          font-size: 22px;
          margin-bottom: 5px !important;
        }
      }

      &_active {
        color: #f0f0f0 !important;
        background: #68a9b8;
        // box-shadow: 0px 6px 15px 5px lightgray;
      }

      @media screen and (max-width: 1024px) {
        width: calc(100% - 52px);
        font-size: 22px;
        margin-bottom: 15px;
      }
    }
  }

  .arrow {
    position: absolute;
    transform: rotateZ(180deg);
    right: -75px;
    bottom: 30px;
    z-index: 0;
    width: 130px;
  }
}
