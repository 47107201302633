.contact {
  max-width: 1100px;
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 15px;
  }

  &__wrapper {
    background: url('../../../../public/images/footer_bg.png');
    @media screen and (max-width: 1024px) {
      background-size: 160%;
      background-position: bottom left;
    }
    background-size: 100% 80%;
    background-repeat: no-repeat;
    width: 100%;
    background-position: bottom;
  }

  .header {
    font-family: "FuturaBookC", sans-serif;
    font-weight: 400;
    font-size: 54px;
    margin-bottom: 37px;
    margin-top: 78px;
     @media screen and (max-width: 1024px) {
      margin-top:50px;
      margin-bottom: 22px;
      font-weight: 500;
      font-size: 36px;
    }
  }

  .description {
    font-size: 28px;
    margin-bottom: 64px;

    @media screen and (max-width: 1024px) {
      font-size: 22px;
      margin-bottom: 59px;
      font-weight: 600;
    }
  }

  .form {
    position: relative;
    z-index: 1;
    margin: auto;
    width: max-content;
    display: flex;
    flex-direction: column;
    background: url('../../../../public/images/formbg.png');
    padding: 55px 38px 40px 82px;
    background-size: 100% 100%;
    margin-bottom:180px;
    @media screen and (max-width: 1024px) {
      z-index:0;
      background: #68A9B8;
      border-radius: 20px;
      padding: 40px;
      margin-bottom: -20px;
    }

    .title {
      font-family: 'FuturaBookC', sans-serif;
      font-weight: 300;
      font-size: 28px;
      color: white;
      margin-bottom: 20px;
      text-align: left;
    }

    input {
      background: #CAE1E6;
      border: none;
      border-radius: 5px;
      margin-bottom: 12px;
      padding: 13px 16px;
      width: 264px;
      font-size: 17px;
    }

    button {
      font-family: 'FuturaBookC', sans-serif;
      font-weight: 400;
      background: #CAE1E6;
      color: #68A9B8;
      border: none;
      border-radius: 5px;
      margin-bottom: 12px;
      padding: 13px 16px;
      width: 264px;
      font-size: 24px;
    }

    textarea {
      background: #CAE1E6;
      border: none;
      border-radius: 5px;
      padding: 13px 16px;
      width: 264px;
      height: 124px;
      font-size: 17px;
      margin-bottom: 12px;
    }
  }

  .tg_link {
    position: absolute;
    width: 100%;
    background: url('../../../../public/images/footerarrow.png');
    height: 325px;
    bottom: -40px;
    left: 0;
    z-index:0;
    @media screen and (max-width: 1024px) {
      display: none;
    }

    span {
      position: absolute;
      right: 194px;
      height: 110px;
      bottom: 114px;
      font-size: 21px;
      color: white;
    }
  }

  .daily {
    position: absolute;
    bottom: -150px;
    left: -170px;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .daily__mobile {
    display: none;
    width: 50%;

    @media screen and (max-width: 1024px){
      margin-left: 30px;
      position: relative;
      display: block;
      z-index: 9999;
    }
  }

  &_info {
    text-align: center;
    margin-bottom: 48px;
    font-size:18px;
  }
}
