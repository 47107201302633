.main_screen {
  max-width: 1100px;
  width: 100%;
  margin: auto;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 15px;
  }

  &__wrapper {
    background-image: url('../../../../public/images/mainline.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      background: none;
    }
  }

  .nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    padding: 30px 0;

    .item {
      font-family: 'FuturaBookC', sans-serif;
      font-size: 21px;
      font-weight: 400;
    }

    .logo {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .item {
      @media screen and (max-width: 1024px) {
        display: none;
      }

    }

    .button {
    }
  }

  .info {
    max-width: 930px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin: auto;

    * {
      margin: auto;
    }

    img {
      width: 300px;
    }

    .header {
      font-family: 'FuturaBookC', sans-serif;
      color: #004F69;
      font-size: 53px;
      text-weight: 600;
      text-align: center;
      margin-top: 240px;
      margin-bottom: 47px;

      @media screen and (max-width: 1024px) {
        display: none;
      }

      &_mobile {
        display: none;
        margin-top: 50px;
        margin-bottom: 27px;

        div {
          font-family: 'FuturaBookC', sans-serif;
          color: #004F69;
          font-size: 40px;
          text-weight: 600;
          text-align: center;
          line-height: 38px;
        }

        @media screen and (max-width: 1024px) {
          display: block;
        }
      }

      img {
        margin-bottom: -30px;
        margin-right:-46px;
      }
    }

    .description {
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 64px;
      @media screen and (max-width: 1024px) {
        font-size: 24px;
        margin-bottom: 44px;
      }
    }

    .linkButton {
      font-family: 'FuturaBookC', sans-serif;
      border-radius: 24px;
      color: white;
      background: #91D8DE;
      margin-bottom: 64px;
      font-size: 28px;
      padding: 24px 40px;
      @media screen and (max-width: 1024px) {
        //font-size: 24px;
        margin-bottom: 54px;
      }
    }
  }

  .preview {
    position: relative;
    img {
      max-width: 100%;
    }

    .arrow {
      position: absolute;
      z-index: -1;
      width: 150px !important;
      bottom: -20px;
      left: -30px;
      transform: rotateZ(90deg);
      @media screen and (max-width: 1024px) {
        width: 100px!important;
        bottom: -40px;
        left: 10px;
      }
    }
  }

  .hello-daily {
    position: absolute;
    top: 170px;
    left: -212px;
    width: 42%;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}