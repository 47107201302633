@import "keep-react/css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "FuturaDemiC";
  src: url("../public/fonts/FuturaDemiC.eot");
  src:
    local("Futura Demi Cyrillic"),
    local("FuturaDemiC"),
    url("../public/fonts/FuturaDemiC.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/FuturaDemiC.woff") format("woff"),
    url("../public/fonts/FuturaDemiC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaMediumC";
  src: url("../public/fonts/FuturaMediumC.eot");
  src:
    local("Futura Medium Cyrillic"),
    local("FuturaMediumC"),
    url("../public/fonts/FuturaMediumC.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/FuturaMediumC.woff") format("woff"),
    url("../public/fonts/FuturaMediumC.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "FuturaLightC";
  src: url("../public/fonts/FuturaLightC-Italic.eot");
  src:
    local("Futura Light Italic Cyrillic"),
    local("FuturaLightC-Italic"),
    url("../public/fonts/FuturaLightC-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/FuturaLightC-Italic.woff") format("woff"),
    url("../public/fonts/FuturaLightC-Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "FuturaMediumC";
  src: url("../public/fonts/FuturaMediumC-Italic.eot");
  src:
    local("Futura Medium Italic Cyrillic"),
    local("FuturaMediumC-Italic"),
    url("../public/fonts/FuturaMediumC-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/FuturaMediumC-Italic.woff") format("woff"),
    url("../public/fonts/FuturaMediumC-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "FuturaLightC";
  src: url("../public/fonts/FuturaLightC.eot");
  src:
    local("Futura Light Cyrillic"),
    local("FuturaLightC"),
    url("../public/fonts/FuturaLightC.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/FuturaLightC.woff") format("woff"),
    url("../public/fonts/FuturaLightC.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "FuturaDemiC";
  src: url("../public/fonts/FuturaDemiC-Italic.eot");
  src:
    local("Futura Demi Italic Cyrillic"),
    local("FuturaDemiC-Italic"),
    url("../public/fonts/FuturaDemiC-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/FuturaDemiC-Italic.woff") format("woff"),
    url("../public/fonts/FuturaDemiC-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "FuturaBookC";
  src: url("../public/fonts/FuturaBookC-Italic.eot");
  src:
    local("Futura Book Italic Cyrillic"),
    local("FuturaBookC-Italic"),
    url("../public/fonts/FuturaBookC-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/FuturaBookC-Italic.woff") format("woff"),
    url("../public/fonts/FuturaBookC-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "FuturaBookC";
  src: url("../public/fonts/FuturaBookC.eot");
  src:
    local("Futura Book Cyrillic"),
    local("FuturaBookC"),
    url("../public/fonts/FuturaBookC.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/FuturaBookC.woff") format("woff"),
    url("../public/fonts/FuturaBookC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "FuturaLightC", sans-serif;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 21px;
}

@media screen and (max-width: 1024px) {
  * {
    font-weight: 500;
    font-size: 18px;
  }
  p,
  p span {
    font-weight: 700;
    font-size: 21px;
  }
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: #004f69;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, #fefefe, #92d9de);
}

a {
  color: inherit;
  text-decoration: none;
  font-size: 18px;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@media screen and (max-width: 1024px) {
  padding: 15px;
}
