.more_info {
  position: relative;
  max-width: 1100px;
  width: 100%;
  margin: auto;
  margin-bottom: 120px;
  @media screen and (max-width: 1024px) {
    padding: 15px;
    margin-bottom: 60px;
  }

  &__wrapper {
    background-image: url('../../../../public/images/moreline.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 200px;
    width: 100%;
  }

  &__bg {
    position: absolute;
    z-index: -1;
    width: 150px !important;
    top: 190px;
    left: -70px;
    @media screen and (max-width: 1024px) {
      display: none;
      width: 100px!important;
      top: 130px;
      left: -20px;
    }
  }

  &__mobile_img {
    display: none;
    position: relative;

    @media screen and (max-width: 1024px){
      display: block;
      margin-bottom: 15px;
    }

    img {
      width: 100%;
    }

    img:last-child {
     position: absolute;
      display: none;
      z-index: -1;
      width: 150px !important;
      top: 190px;
      left: -70px;
      @media screen and (max-width: 1024px) {
        display: block;
        width: 100px!important;
        top: 130px;
        left: -20px;
      }
    }

  }

  .split {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    img {
      height: auto;
      width: 59%;
      @media screen and (max-width: 1024px){
        display: none;
        width: 100%;
      }
    }
  }

  p {
    @media screen and (max-width: 1024px) {
      span {
        display: none;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }
    img {
      height: 29px!important;
      width: auto!important;
    }

    &.p_mobile {
      br {
        display: none;
        @media screen and (max-width: 1024px) {
          display: block;
        }
      }
    }
  }

  p {
    img {
      margin-bottom: -8px;
      margin-right: -2px;
      margin-left: 2px;
    }
  }

  .info {

  }

  .header {
    font-family: "FuturaBookC", sans-serif;
    font-weight: 400;
    font-size: 54px;
    margin-bottom: 16px;
    margin-top: 26px;

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: max-content;
      margin: auto;
    }

    @media screen and (max-width: 1024px) {
      text-align: center;
      font-weight: 500;
      font-size: 36px;
      margin-bottom: 6px;
    }
  }

  .subheader {
    font-size: 28px;
    margin-bottom: 60px;

    @media screen and (max-width: 1024px) {
      text-align: center;
      font-size: 22px;
      margin-bottom: 40px;
      font-weight: 600;
    }
  }
}