.possibilities {
  max-width: 1100px;
  width: 100%;
  text-align: center;
  margin-bottom: 180px;
  @media screen and (max-width: 1024px) {
    padding: 15px;
    margin-bottom: 60px;
  }

  .header {
    font-family: 'FuturaBookC', sans-serif;
    font-weight: 400;
    font-size: 54px;
    margin-bottom: 47px;
    @media screen and (max-width: 1024px) {
      margin-top:10px;
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 36px;
    }
  }

  .description {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 64px;
    @media screen and (max-width: 1024px) {
      font-size: 22px;
      margin-bottom: 59px;
    }

    img {
      margin-bottom: -10px;
    }

    b {
      font-family: 'FuturaBookC', sans-serif;
      font-size: 28px;
      font-weight: 400;
      @media screen and (max-width: 1024px) {
        font-weight: 600;
        font-size: 22px;
      }
    }
  }

  .item {
    width: 47%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
      width: 90%;

      img {
        aspect-ratio: 1;
      }
    }


    &__wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 68px;
      column-gap: 62px;
    }

    &__icon {
      margin-right: 18px;
    }

    &__info {
      text-align: left;
      display: flex;
      flex-direction: column;
    }

    &__header {
      font-size: 32px;
      font-family: 'FuturaBookC', sans-serif;
      margin-bottom: 6px;
      @media screen and (max-width: 1024px) {
        font-size: 24px;
        font-weight: 600;
      }
    }

    &__description {
      font-weight: 600;
    }
  }
}