.feature {
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 15px;
  }

  &__wrapper {
    position: relative;
    background: url('../../../../public/images/feature-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width:100%;
    //margin-bottom: 124px;
    padding-bottom: 56px;
    margin-top: -30px;
    @media screen and (max-width: 1024px) {
      background: #69A9B8;
      padding-bottom: 0;
    }

    .arrow {
      display: none;
      position: absolute;
      width: 150px !important;
      bottom: -20px;
      left: -30px;
      transform: rotateZ(90deg);
      @media screen and (max-width: 1024px) {
        position: absolute;
        display: block;
        width: 100px!important;
        z-index: 999!important;
        top: -40px;
        left: 10px;
      }
    }
  }
  max-width: 1100px;
  width: 100%;
  margin: auto;

  * {
    color: white!important;
  }

  .split {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    img {
      height: auto;
      width: 59%;
      margin-top: -42px;
      &.image__mobile {
        display: none;
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-top: 0;
        display: none;

        &.image__mobile {
          display: block;
          margin-bottom:20px;
        }
      }
    }
  }

  p {
    font-weight: 400;
    @media screen and (max-width: 1024px) {
      font-size: 21px;
      font-weight: 500;
    }
    img {
      height: 29px!important;
      width: auto!important;
    }
  }

  .info {

    p {
      //width: 90%;
    }

    p:last-child {
      margin-bottom: 47px;
    }
  }

  .header {
    font-family: "FuturaBookC", sans-serif;
    font-weight: 400;
    font-size: 54px;
    margin-bottom: 16px;
    margin-top: 78px;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    &__wrapper {
      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: max-content;
        margin: auto;
      }
    }

    &__mobile {
      display: none;
      @media screen and (max-width: 1024px) {
        display: block;
        font-family: "FuturaBookC", sans-serif;
        font-weight: 400;
        font-size: 36px;
        margin-bottom: 6px;
        margin-top: 28px;
    }

    }
  }

  .subheader {
    font-size: 28px;
    margin-bottom: 64px;
    @media screen and (max-width: 1024px) {
      font-size: 22px;
      margin-bottom: 44px;
    }
  }

  .daily {
    position: absolute;
    right: -290px;
    top: -13px;
  }
}